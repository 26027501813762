// Polyfills
import '@/lib/polyfills/replaceAll'
import '@/lib/polyfills/globalThis'

import { MantineProvider, useMantineTheme } from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { DefaultSeo } from 'next-seo'
import { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { install } from 'resize-observer'
import { SWRConfig } from 'swr'

import ForceMaintenanceGuard from '@/guards/ForceMaintenanceGuard'
import { defaultMantineTheme } from '@/lib/theme'
import { fetcher, SEO } from '@/lib/utils'
import { gtmSetup } from '@/lib/utils/gtm'
import AuthProvider from '@/providers/AuthProvider'
import NavProvider from '@/providers/NavProvider'
import RouterLoadingProvider from '@/providers/RouterLoadingProvider'

function MyApp({ Component, pageProps }: AppProps) {
  // Hooks
  const theme = useMantineTheme()

  // Polyfills
  if (typeof window !== 'undefined') {
    install()
  }

  useEffect(() => {
    setTimeout(() => {
      gtmSetup()
    }, 5000)
  }, [])

  return (
    <>
      <Head>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <DefaultSeo
        dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'}
        dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'}
        {...SEO}
      />
      <SWRConfig value={{ fetcher }}>
        <AuthProvider>
          <NavProvider>
            <MantineProvider theme={defaultMantineTheme(theme)} withGlobalStyles withNormalizeCSS>
              <IntlProvider locale="pt-br" defaultLocale="pt-br">
                <NotificationsProvider position="top-right" autoClose={3000}>
                  <RouterLoadingProvider>
                    <ForceMaintenanceGuard>
                      <Component {...pageProps} />
                    </ForceMaintenanceGuard>
                  </RouterLoadingProvider>
                </NotificationsProvider>
              </IntlProvider>
            </MantineProvider>
          </NavProvider>
        </AuthProvider>
      </SWRConfig>
    </>
  )
}

export default MyApp
