import { useRouter } from 'next/router'
import { KeyedMutator } from 'swr'

import { StudentSiteProfile as StudentSiteProfileType } from '@/types'

import { getSession, getUrlString } from '../utils'
import useFetch from './useFetch'

export default function useStudent(): {
  studentData: StudentSiteProfileType,
  mutate: KeyedMutator<any>
  } {
  // Hooks
  const router = useRouter()

  // Constants
  const { site } = router.query || {}
  const siteSlug = getUrlString(site)
  const sessionStudentUid = getSession('stuid')

  // Fetch
  const { data: studentData, mutate } = useFetch([
    siteSlug && sessionStudentUid ? `/${siteSlug}/students/${sessionStudentUid}/` : null
  ])

  return {
    studentData,
    mutate
  }
}
