import dayjs from 'dayjs'
import isDate from 'lodash/isDate'
import isString from 'lodash/isString'

const defaultOptions = {
  format: undefined,
  display: {
    date: 'DD/MM/YYYY',
    time: 'HH:mm',
    datetime: 'DD/MM/YYYY HH:mm'
  }
}

export function displayer(inputDayjs: any, options?: any) {
  if (!dayjs.isDayjs(inputDayjs)) { return { date: undefined, time: undefined, datetime: undefined } }

  if (!options) {
    options = defaultOptions
  }

  return {
    date: inputDayjs.format(options.display.date),
    time: inputDayjs.format(options.display.time),
    datetime: inputDayjs.format(options.display.datetime)
  }
}

function response(input: any, raw: any, options: any) {
  const { format } = options

  return { input, output: raw.format(format), raw, display: displayer(raw, options) }
}

export function datter(input: any, options = defaultOptions) {
  const inputIsDayjs = dayjs.isDayjs(input)
  const inputIsDate = isDate(input)
  const inputIsString = isString(input)

  if (!options) throw Error('Options is mandatory for datter')

  const mergedOptions = { ...defaultOptions, ...options }
  const { format } = mergedOptions

  if (input === undefined) {
    const raw = dayjs()

    return response(raw.format(), raw, mergedOptions)
  }

  if (inputIsDayjs) {
    const raw = input

    return response(input, raw, mergedOptions)
  }

  if (inputIsDate) {
    const raw = dayjs(input)

    return response(input, raw, mergedOptions)
  }

  if (inputIsString) {
    const raw = dayjs(input, format)

    return response(input, raw, mergedOptions)
  }

  return { input, output: null, raw: null, display: displayer(input, mergedOptions) }
}

export const dateToHuman = (date: string) => {
  const dateObject = new Date(date)
  return new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(dateObject)
}
