import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'

import { getSession, removeSession } from '@/lib/utils'

import { useAuth } from './AuthProvider'

interface ContextInterface {
  drawerPosition: 'right' | 'left'
  basketDrawerOpen: boolean
  studentModalOpen: boolean
  studentModalOpenFromBuyButton: boolean
  setDrawerPosition?: Dispatch<SetStateAction<'right' | 'left'>>
  setBasketDrawerOpen?: Dispatch<SetStateAction<boolean>>
  setStudentModalOpen?: Dispatch<SetStateAction<boolean>>
  setStudentModalOpenFromBuyButton?: Dispatch<SetStateAction<boolean>>
}

const NavContext = createContext<ContextInterface>({
  drawerPosition: 'right',
  basketDrawerOpen: false,
  studentModalOpen: false,
  studentModalOpenFromBuyButton: false
})

interface Props {
  children: React.ReactNode
}

export const useNav = () => useContext(NavContext)

function useProvideNav() {
  // Hooks
  const { isAuthenticated } = useAuth() || {}

  // Constants
  const sessionStudentUid = getSession('stuid')

  // States
  const [drawerPosition, setDrawerPosition] = useState<'right' | 'left'>('right')
  const [basketDrawerOpen, setBasketDrawerOpen] = useState<boolean>(false)
  const [studentModalOpen, setStudentModalOpen] = useState<boolean>(false)
  const [studentModalOpenFromBuyButton, setStudentModalOpenFromBuyButton] = useState<boolean>(false)

  // Effects
  useEffect(() => {
    if (isAuthenticated === false && !!sessionStudentUid) {
      removeSession('stuid')
    }
  }, [isAuthenticated, sessionStudentUid])

  return {
    drawerPosition,
    basketDrawerOpen,
    studentModalOpen,
    studentModalOpenFromBuyButton,
    setDrawerPosition,
    setBasketDrawerOpen,
    setStudentModalOpen,
    setStudentModalOpenFromBuyButton
  }
}

export default function NavProvider({ children }: Props) {
  const nav = useProvideNav()
  return <NavContext.Provider value={nav}>{children}</NavContext.Provider>
}
