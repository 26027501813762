import { Contract as ContractType, Price as PriceType, Product as ProductType, ProductTypes, SimplePayment } from '@/types'

export const getProductInfo = (product:ProductType, display:boolean = false) => {
  let type:ProductTypes = 'simple'
  let price:PriceType|undefined = product?.price
  let payments:SimplePayment[] = []
  let contracts:ContractType[] = []
  if (product?.structure === 'parent' && product?.children?.length > 0) {
    type = 'variable'
    if (display) {
      price = product?.priceRange?.lowest
    }
  } else if (product?.hasKit && product?.kit?.length > 0) {
    type = 'kit'
    if (product?.isKitBundler) {
      price = product?.priceKit
    }
    if (price?.type === 'contract') {
      payments = price?.contract?.payments || []
      if (price?.contract?.structure === 'parent') {
        contracts = price.contract?.children || []
      }
    } else if (price?.type === 'tuition') {
      payments = price?.tuition?.payments || []
    }
  } else if (price?.type === 'contract') {
    type = 'contract'
    payments = price?.contract?.payments || []
    if (price?.contract?.structure === 'parent') {
      contracts = price?.contract?.children || []
    }
  } else if (price?.type === 'tuition') {
    type = 'tuition'
    payments = price?.tuition?.payments || []
  }

  return { type, price, payments, contracts }
}
