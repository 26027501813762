import useSWR from 'swr'

function useFetch(...args: Array<any>) {
  const [params, options] = args

  const [url, query = {}] = params
  const queryObject = new URLSearchParams(query)
  const newQuery = queryObject.toString()
  const newUrl = newQuery ? `${url}?${newQuery}` : url

  const { data, error, isLoading, isValidating, mutate } = useSWR(url ? newUrl : null, options)

  return { data, error, isLoading, isValidating, mutate }
}

export default useFetch
