import axios, { AxiosResponse } from 'axios'
import { useRouter } from 'next/router'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'

import { useFetch } from '@/lib/hooks'
import { api, getCookie, getUrlString, removeCookie, removeSession, setCookie } from '@/lib/utils'
import { UserMe as UserMeType, UserPermissions as UserPermissionsType } from '@/types'

type Credentials = {
  login: string
  password: string
}

interface ContextInterface {
  login: (credentials: Credentials) => void
  loginOneTimeToken: (token: string) => Promise<AxiosResponse>
  logout: () => void
  forgotPassword: (email: string) => Promise<AxiosResponse>
  resetPassword: (password: string, uidb64: string, token: string) => Promise<AxiosResponse>
  verifyToken: () => boolean
  isAuthenticated: boolean | null
  isValidating: boolean
  userData: UserMeType
  permissionsData: UserPermissionsType
}

const AuthContext = createContext<ContextInterface | null>(null)

interface Props {
  children: any
}

export const useAuth = () => useContext(AuthContext)

function useProvideAuth() {
  const { cache }: { cache: any } = useSWRConfig()
  const router = useRouter()
  const { site: domain, token: sesameToken } = router.query || {}

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)

  const { token: cookieToken, expiry: cookieExpiry } = getCookie('eskolare-shop-token') || {}

  const {
    data: userData,
    isValidating: userIsValidating,
    mutate: userMutate
  } = useFetch([isAuthenticated && domain ? `/${domain}/accounts/me/` : null])

  const {
    data: permissionsData,
    isValidating: permissionsIsValidating,
    mutate: permissionsMutate
  } = useFetch([isAuthenticated && domain ? `/${domain}/accounts/permissions/` : null])

  const login = async (credentials: Credentials) => {
    const response = await api.post(`/${domain}/accounts/login/`, null, {
      headers: {
        Authorization: `Basic ${Buffer.from(
          `${credentials.login}:${credentials.password}`
        ).toString('base64')}`
      }
    })

    const { data, status } = response || {}

    if (status === 200) {
      const tokenData = { expiry: data?.expiry, token: data?.token }
      setCookie('eskolare-shop-token', tokenData)
      setIsAuthenticated(true)
    }

    return response
  }

  const loginOneTimeToken = async (oneTimeToken: string) => {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_ENTRYPOINT}/${domain}/accounts/login/`,
      null,
      { headers: { Authorization: `OneTimeToken ${oneTimeToken}` } }
    )
    const { data, status } = response || {}

    if (status === 200) {
      const tokenData = { expiry: data?.expiry, token: data?.token }
      setCookie('eskolare-shop-token', tokenData)
      setIsAuthenticated(true)
      userMutate()
      permissionsMutate()
    }

    return response
  }

  const loginSesame = useCallback(async () => {
    const tokenSesame = getUrlString(sesameToken)
    const response = await api.post(`/${domain}/accounts/login/?sesame=${tokenSesame}`)

    const { data, status } = response || {}

    if (status === 200) {
      const tokenData = { expiry: data?.expiry, token: data?.token }
      setCookie('eskolare-shop-token', tokenData)
      setIsAuthenticated(true)
    }

    return response
  }, [domain, sesameToken])

  const logout = async () => {
    try {
      await api.post(`/${domain}/accounts/logout/`)
    } finally {
      removeCookie('eskolare-shop-token')
      removeSession('stuid')
      setIsAuthenticated(false)
    }
  }

  const forgotPassword = async (email: string) => {
    const response = await api.post(`/${domain}/password-reset/`, { email })
    return response
  }

  const resetPassword = async (password: string, uidb64: string, hash: string) => {
    const response = await api.post(`/${domain}/password-reset/confirm/`, {
      password,
      uidb64,
      token: hash
    })
    return response
  }

  const verifyToken = useCallback(() => {
    let expired = true
    if (cookieToken) {
      const expiryDate = new Date(cookieExpiry)
      const nowDate = new Date()

      expired = nowDate.getTime() > expiryDate.getTime()

      if (expired) {
        removeCookie('eskolare-shop-token')
        removeSession('stuid')
      }
    }

    return !expired
  }, [cookieToken, cookieExpiry])

  // Effects
  useEffect(() => {
    const isValidToken = verifyToken()
    setIsAuthenticated(!!isValidToken)
  }, [verifyToken, cookieToken])

  useEffect(() => {
    if (sesameToken) {
      if (!isAuthenticated) {
        loginSesame()
      } else if (window) {
        const params = new URL(window.location.href)?.searchParams
        params.delete('token')
        router.push({ pathname: window.location.pathname, query: params.toString() }, undefined, {
          shallow: false
        })
      }
    }
  }, [isAuthenticated, loginSesame, router, sesameToken])

  // Clear all SWR cache (experimental)
  useEffect(() => {
    cache?.clear?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return {
    login,
    logout,
    loginOneTimeToken,
    forgotPassword,
    resetPassword,
    verifyToken,
    isAuthenticated,
    isValidating: userIsValidating || permissionsIsValidating,
    userData,
    permissionsData
  }
}

export default function AuthProvider({ children }: Props) {
  const auth = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
