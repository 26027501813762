import { CSSObject, MantineTheme, MantineThemeOverride } from '@mantine/core'

const Inputs = [
  'Autocomplete',
  'ColorInput',
  'FileInput',
  'Input',
  'JsonInput',
  'MultiSelect',
  'NativeSelect',
  'NumberInput',
  'PasswordInput',
  'Select',
  'Textarea',
  'TextInput'
].reduce(
  (object, item) => ({
    ...object,
    [item]: { defaultProps: { size: 'md' } }
  }),
  {}
)

const hover = { '&:hover': { cursor: 'pointer' } }

const defaultMantineTheme = (theme: MantineThemeOverride) => ({
  fontFamily: 'Open Sans, Helvetica, Verdana, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  black: '#333',
  headings: {
    fontFamily: 'Heebo, sans-serif',
    fontWeight: 700
  },
  components: {
    ActionIcon: { defaultProps: { size: 'lg', radius: 'xl' } },
    Badge: {
      defaultProps: { radius: 'sm' },
      styles: {
        root: { textTransform: 'capitalize' } as CSSObject
      }
    },
    Button: {
      defaultProps: { uppercase: true, loaderProps: { variant: 'oval' } },
      styles: (theme: MantineTheme) => ({
        root: {
          overflow: 'hidden',
          '&[data-disabled]': {
            backgroundColor: theme.colors.gray[3]
          },
          '&[data-loading]': {
            color: theme.colors.gray[7],
            backgroundColor: theme.colors.gray[3],
            svg: { stroke: theme.colors.gray[7] }
          }
        },
        label: { overflow: 'hidden' }
      })
    },
    Drawer: {
      defaultProps: {
        overlayColor: theme.colors?.gray?.[3],
        overlayOpacity: 0.8,
        overlayBlur: 3
      }
    },
    LoadingOverlay: {
      defaultProps: {
        loaderProps: { variant: 'dots' },
        overlayColor: theme.colors?.gray?.[0],
        overlayOpacity: 0.8,
        overlayBlur: 3
      }
    },
    Modal: {
      defaultProps: {
        overlayColor: theme.colors?.gray?.[3],
        overlayOpacity: 0.8,
        overlayBlur: 3
      }
    },
    Text: { defaultProps: { color: theme.colors?.gray?.[7] } },
    Title: { defaultProps: { color: theme.colors?.gray?.[7] } },
    InputWrapper: {
      inputWrapperOrder: ['label', 'input', 'error', 'description'],
      styles: (theme: MantineTheme) => ({
        label: { fontSize: theme.fontSizes.sm },
        description: { fontSize: theme.fontSizes.xs },
        error: { fontSize: theme.fontSizes.xs }
      })
    },
    Checkbox: {
      styles: () => ({
        input: hover,
        label: hover,
        labelWrapper: { display: 'flex', alignItems: 'flex-start', ...hover }
      })
    },
    Radio: {
      styles: () => ({
        radio: hover,
        label: hover,
        labelWrapper: { display: 'flex', alignItems: 'flex-start', ...hover }
      })
    },
    Switch: {
      styles: () => ({
        track: hover,
        trackLabel: hover,
        label: hover,
        labelWrapper: { display: 'flex', alignItems: 'flex-start', ...hover }
      })
    },
    ...Inputs
  }
})

export default defaultMantineTheme
