import { useEffect } from 'react'
import { Middleware, SWRHook } from 'swr'

const swrCallbackMiddleware = (callback: (data: any) => void) => {
  const swrMiddleware: Middleware = (useSWRNext: SWRHook) => (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config)
    useEffect(() => {
      if (swr.data !== undefined) callback(swr.data)
    }, [swr.data])
    return useSWRNext(key, fetcher, config)
  }
  return swrMiddleware
}

export default swrCallbackMiddleware
