export default function errorHandler(error: any) {
  let detail: String[] = []
  let nonFieldErrors: String[] = []
  let fieldErrors: String[] = []
  let fields: String[] = []

  const errorCheck = (errors: any) => {
    if (!!errors && typeof errors === 'object') {
      if (errors.detail) {
        detail = [...detail, errors.detail]
      }

      if (errors.nonFieldErrors && Array.isArray(nonFieldErrors)) {
        nonFieldErrors = [...nonFieldErrors, ...errors.nonFieldErrors]
      }

      if (!Array.isArray(errors)) {
        const keys = Object.keys(errors).filter(key => key !== 'detail' && key !== 'nonFieldErrors')
        if (!keys.length) return
        keys.forEach(key => {
          if (Array.isArray(errors[key])) {
            fields = [...fields, key]
            fieldErrors = [...fieldErrors, ...errors[key]]
          } else {
            errorCheck(errors[key])
          }
        })
      }
    }
    return null
  }

  errorCheck(error)

  nonFieldErrors = nonFieldErrors.filter(err => typeof err === 'string')
  fieldErrors = fieldErrors.filter(err => typeof err === 'string')
  fields = fields.filter(err => typeof err === 'string')

  const stringFilter = (string: any, index: any, array: any) =>
    index === array.findIndex((item: any) => item === string)

  return {
    messages: [...detail, ...nonFieldErrors, ...fieldErrors].filter(stringFilter) || null,
    fields: fields.filter(stringFilter)
  }
}
