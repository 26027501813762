import { LoadingOverlay } from '@mantine/core'
import {
  completeNavigationProgress,
  NavigationProgress,
  startNavigationProgress
} from '@mantine/nprogress'
import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'

interface ContextInterface {
  isLoading: boolean
  setIsLoading?: (state: boolean) => void
}

const RouterLoadingContext = createContext<ContextInterface>({ isLoading: false })

export const useRouterLoading = () => useContext(RouterLoadingContext)

export default function RouterLoadingProvider({ children }: { children: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()

  useEffect(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    const handleStart = () => {
      setIsLoading(true)
      startNavigationProgress()
    }
    const handleStop = () => {
      setIsLoading(false)
      completeNavigationProgress()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  return (
    <RouterLoadingContext.Provider
      value={{
        isLoading,
        setIsLoading: (state: boolean) => {
          if (state) {
            setIsLoading(true)
            startNavigationProgress()
          } else {
            setIsLoading(false)
            completeNavigationProgress()
          }
        }
      }}>
      <NavigationProgress autoReset={true} />
      <LoadingOverlay
        visible={isLoading}
        loader={<></>}
        overlayColor="white"
        overlayOpacity={0.3}
        overlayBlur={0}
        sx={{ position: 'fixed' }}
      />
      {children}
    </RouterLoadingContext.Provider>
  )
}
