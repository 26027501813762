import { get, isArray, isDate, isObject } from 'lodash'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

import { datter } from '@/lib/utils'
import {
  BasketProductOption as BasketProductOptionType,
  ProductOption as ProductOptionType,
  ProductOptionValidator as ProductOptionValidatorType
} from '@/types'

export function clean(values: any): BasketProductOptionType[] {
  const newValues = Object.keys(values).map(key => {
    const value = values[key]

    if (isArray(value)) {
      const newValue = value.filter((i: number) => i).map((i: number) => [i, '1'].join('='))
      return { code: key, value: newValue.join(',') }
    }

    if (isObject(value) && !isDate(value)) {
      /**
       * Transform the object { embalagem-padrao: true, plastico: true }
       * to embalagem-padrao=1,plastico=1
       */
      const newValue = Object.keys(value)
        .filter((i: string) => get(value, i))
        .map((i: string) => [i, get(value, i) === true ? 1 : get(value, i)].join('='))

      return { code: key, value: newValue.join(',') }
    }

    if (value === true) {
      return { code: key, value: value.toString() }
    }

    return { code: key, value }
  })

  return newValues.filter(({ value }) => !!value)
}

export function getValueByType(type: string, value: any) {
  switch (type) {
    case 'integer':
    case 'float':
      return Number(value)
    case 'text':
    case 'richtext':
      return value || ''
    case 'boolean':
      return value === 'true'
    case 'datetime':
    case 'date':
      return datter(value).output
    case 'multioption':
      return value?.split?.(',').filter((value: any) => Boolean(value) && typeof value === 'string')
    case 'option':
      return value || ''
    default: {
      return value
    }
  }
}

export function initialValues(options?: ProductOptionType[]) {
  if (options) {
    const newOptions = options
      .filter(item => item.defaultValue)
      .map(item => {
        const { code, type, defaultValue } = item

        return { code, value: getValueByType(type, defaultValue) }
      })

    return mapValues(keyBy(newOptions, 'code'), 'value')
  }
  return []
}

export function validate(options: ProductOptionType[], values: any) {
  let validation = false

  const newOptions = keyBy(
    options.filter(option => option.isRequired),
    'code'
  )

  Object.keys(newOptions).map(key => {
    const value = values[key]

    if (!value) validation = true

    return key
  })

  return validation
}

export function propsValidators(options: ProductOptionValidatorType[]) {
  const inputProps = mapValues(keyBy(options, 'type'), 'value')

  const { min, max, maxlength, minlength } = inputProps

  const helperText = () => {
    if (min && max) return `Mínimo: ${min}, Máximo: ${max}`
    if (min) return `Mínimo: ${min}`
    if (max) return `Máximo: ${max}`
    if (minlength) return `Mínimo de ${minlength} caracteres`
    if (maxlength) return `Máximo de ${maxlength} caracteres`

    return null
  }

  return {
    min,
    max,
    maxLength: Number(maxlength) ? Number(maxlength) : undefined,
    minLength: Number(minlength) ? Number(minlength) : undefined,
    description: helperText()
  }
}

export function emptyValues(type: string) {
  switch (type) {
    case 'integer':
    case 'float':
      return 0
    case 'text':
    case 'richtext':
      return ''
    case 'boolean':
      return false
    case 'datetime':
    case 'date':
      return null
    case 'multioption':
      return undefined
    case 'option':
      return undefined
    default: {
      return {}
    }
  }
}

export function propsConstructor(option: ProductOptionType, value: any) {
  const { name, type, code, isRequired, values = [], validators, attributes = {} } = option

  const { placeholder, label, disabled } = attributes

  switch (type) {
    case 'integer':
    case 'float':
    case 'text': {
      return {
        name: code,
        placeholder,
        required: isRequired,
        label: label || name,
        value: value || emptyValues(type),
        disabled,
        ...propsValidators(validators)
      }
    }
    case 'boolean': {
      return {
        name: code,
        disabled
      }
    }
    case 'richtext': {
      return {
        name: code,
        value: value || ''
      }
    }
    case 'datetime':
    case 'date': {
      return {
        name: code,
        margin: 'none',
        required: isRequired,
        label,
        value: value || '',
        disabled,
        ...propsValidators(validators)
      }
    }
    case 'multioption':
    case 'option': {
      return {
        name: code,
        required: isRequired,
        options: values,
        value: value || emptyValues(type),
        disabled
      }
    }
    default: {
      return {}
    }
  }
}
