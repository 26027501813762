export const normalize = (str: string) =>
  str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

const stringCompare = (sentence: string | undefined, searchTerm: string) =>
  typeof sentence === 'string' &&
  typeof searchTerm === 'string' &&
  normalize(sentence).includes(normalize(searchTerm))

export default stringCompare
