const SESSION_KEY = '@eskolare-shop/'

export const removeSession = (name: string) => {
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(`${SESSION_KEY}${name}`)
  }
}

export const getSession = (name: string, isString: boolean = false) => {
  if (typeof window !== 'undefined') {
    const sessionValue = sessionStorage.getItem(`${SESSION_KEY}${name}`)

    if (isString) {
      return sessionValue || ''
    }

    try {
      return JSON.parse(sessionValue as string)
    } catch {
      return ''
    }
  }
  return null
}

export const setSession = (name: string, value: any) => {
  if (typeof window !== 'undefined' && !!value) {
    sessionStorage.setItem(`${SESSION_KEY}${name}`, JSON.stringify(value))
  }
}
