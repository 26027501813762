import { NotificationProps, showNotification } from '@mantine/notifications'

interface Props extends NotificationProps {
  variant: 'success' | 'warning' | 'info' | 'error'
}

const DEFAULT = {
  success: {
    title: 'Sucesso',
    message: 'Requisição realizada com sucesso.',
    color: 'green',
    autoClose: 3000
  },
  warning: {
    title: 'Atenção',
    message: 'A sua requisição requer atenção.',
    color: 'orange',
    autoClose: 8000
  },
  info: {
    title: 'Observação',
    message: 'Requisição realizada.',
    color: 'blue',
    autoClose: 5000
  },
  error: {
    title: 'Erro',
    message: 'Ops, houve um erro em sua requisição.',
    color: 'red',
    autoClose: 10000
  }
}

const notificationHandler = ({ variant, ...props }: Props) => {
  if (!variant) return null
  return showNotification({ ...DEFAULT[variant], ...props })
}

export default notificationHandler
