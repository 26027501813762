import { DefaultSeoProps } from 'next-seo'

export const noIndex = {
  noindex: true,
  nofollow: true,
  robotsProps: {
    noarchive: true,
    nosnippet: true,
    notranslate: true,
    noimageindex: true,
    maxImagePreview: 'none' as any
  }
}

const config: DefaultSeoProps = {
  openGraph: { type: 'website', locale: 'pt_BR' },
  twitter: { cardType: 'summary_large_image' },
  additionalMetaTags: [
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'msapplication-TileColor', content: '#ffffff' },
    { name: 'theme-color', content: '#ffffff' }
  ]
}

export default config
