import {
  Segment as SegmentType,
  SegmentGrade as SegmentGradeType,
  Showcase as ShowcaseType,
  Store as StoreType
} from '@/types'

const getShowcasesFromGrades = (data: any) => {
  const segments = data?.reduce(
    (acc: SegmentGradeType[], grade: SegmentGradeType) => [...acc, ...grade?.segments],
    []
  )

  const stores = segments?.reduce(
    (acc: SegmentType[], segment: SegmentType) => [
      ...acc,
      ...segment?.stores?.map(store => ({ ...store, segmentDisplayName: segment?.displayName }))
    ],
    []
  )

  return stores?.reduce(
    (acc: StoreType[], store: StoreType | any) => [
      ...acc,
      ...store?.showcases?.map((showcase: ShowcaseType) => ({
        ...showcase,
        segmentDisplayName: store?.segmentDisplayName,
        storeDisplayName: store?.displayName
      }))
    ],
    []
  )
}

export default getShowcasesFromGrades
