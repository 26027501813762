import {
  Center,
  Container,
  createStyles,
  Divider,
  Grid,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconBulldozer, IconHammer } from '@tabler/icons'
import Head from 'next/head'
import React from 'react'

interface Props {
  children?: React.ReactNode
}

const useStyles = createStyles(theme => ({
  icon: {
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: theme.radius.sm,
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.red[5],
    backgroundColor: theme.colors.red[0]
  },
  title: {
    flexGrow: 1,
    fontWeight: 900,
    fontSize: 32,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.fn.smallerThan('sm')]: { fontSize: theme.fontSizes.xl, textAlign: 'center' }
  },
  text: {
    fontSize: theme.fontSizes.lg,
    [theme.fn.smallerThan('sm')]: { fontSize: theme.fontSizes.md, textAlign: 'center' }
  }
}))

export default function ForceMaintenanceGuard({ children }: Props) {
  // Hooks
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`)

  // Constants
  const isMaintenance = process.env.NEXT_PUBLIC_ESKOLARE_MAINTENANCE === 'true'
  return isMaintenance ? (
    <>
      <Head>
        <title>Servidores em manutenção</title>
      </Head>
      <Center sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
        <Container size="md" p="xl" sx={{ minWidth: '50%' }}>
          <Divider my="xl" variant="dotted" size="sm" labelPosition="center" />
          <Grid gutter="lg" justify="center">
            <Grid.Col xs={12} sm={8} order={2} orderSm={1}>
              <Stack>
                <Group spacing="xs" position={isSm ? 'center' : 'left'} align="center" noWrap>
                  <IconHammer size={isSm ? 16 : 24} stroke={2.5} color={theme.colors.gray[7]} />
                  <Title className={classes.title}>Servidores em manutenção</Title>
                </Group>
                <Text color="dimmed" className={classes.text}>
                  Nossos servidores estão em manutenção para uma atualização crítica, retornamos em
                  breve.
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col xs={12} sm={4} order={1} orderSm={2}>
              <ThemeIcon variant="light" color="red" className={classes.icon}>
                <IconBulldozer size={isSm ? 72 : 120} stroke={1} />
              </ThemeIcon>
            </Grid.Col>
          </Grid>
          <Divider variant="dotted" size="sm" my="xl" />
        </Container>
      </Center>
    </>
  ) : (
    <>{children}</>
  )
}
